<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline warning lighten-2"
          primary-title
        >
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="continueSession"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SessionContinueDialog',
  data() {
    return {
      dialog: false,
      title: 'Warning',
      message: 'Session expires in 5 minutes, click continue to extend time',
      warnBefore: 5 * 60,
      timer: null,
    }
  },
  computed: {
    ...mapState(['user', 'token', 'timeNow']),
  },
  watch: {
    timeNow(now) {
      console.log(`exp at: ${this.user.exp}`)
      console.log(`current: ${now}`)
      this.dialog = this.user && ((this.user.exp - now) < this.warnBefore)
    },
  },
  methods: {
    continueSession() {
      this.$store.dispatch('renewToken')
        .finally(() => {
          this.dialog = false
        })
    },
  },
}
</script>
