// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/prospective-student-fees',
        params: filter,
      },
      { root: true },
    )
  },
  async create({ dispatch }, prospectiveStudentFee) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/prospective-student-fees',
        data: prospectiveStudentFee,
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/prospective-student-fees/${id}`,
      },
      { root: true },
    )
  },
  async update({ dispatch }, prospectiveStudentFee) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/prospective-student-fees/${prospectiveStudentFee.id}`,
        data: prospectiveStudentFee,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
