// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/applications',
        params: filter,
      },
      { root: true },
    )
  },
  allPlain({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/applications-plain',
        params: filter,
      },
      { root: true },
    )
  },
  async create({ dispatch }, application) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/applications',
        data: application,
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}`,
      },
      { root: true },
    )
  },
  async update({ dispatch }, application) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/applications/${application.id}`,
        data: application,
      },
      { root: true },
    )
  },
  async submit({ dispatch }, application) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/applications/${application.id}/submit`,
        data: application,
      },
      { root: true },
    )
  },
  async sign({ dispatch }, application) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/applications/${application.id}/sign`,
        data: application,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/applications/${id}`,
      },
      { root: true },
    )
  },
  async getPassport({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/passport`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async updatePassport({ dispatch }, { id, fileName, data }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/applications/${id}/passport`,
        data: { passportFileName: fileName, passport: data },
      },
      { root: true },
    )
  },
  async getTranscript({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/transcript`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async updateTranscript({ dispatch }, { id, fileName, data }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/applications/${id}/transcript`,
        data: { transcriptFileName: fileName, transcript: data },
      },
      { root: true },
    )
  },
  async getCreditSummary({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/creditSummary`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async updateCreditSummary({ dispatch }, { id, fileName, data }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/applications/${id}/creditSummary`,
        data: { creditSummaryFileName: fileName, creditSummary: data },
      },
      { root: true },
    )
  },
  async getSignature({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/signature`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async getParentSignature({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/parentSignature`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async getPdf({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/pdf`,
        responseType: 'arraybuffer',
      },
      { root: true },
    )
  },
  async signRequest({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/signRequest`,
      },
      { root: true },
    )
  },
  async approve({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/approve`,
      },
      { root: true },
    )
  },
  async addFee({ dispatch }, { id, fee }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/applications/${id}/fees`,
        data: fee,
      },
      { root: true },
    )
  },
  async allFees({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/applications/${id}/fees`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
