// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/agents',
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/agents/${id}`,
      },
      { root: true },
    )
  },
  async create({ dispatch }, agent) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/agents',
        data: agent,
      },
      { root: true },
    )
  },
  async update({ dispatch }, agent) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/agents/${agent.id}`,
        data: agent,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, agent) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/agents/${agent.id}`,
      },
      { root: true },
    )
  },
  async allReps({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/agents/${id}/representatives`,
      },
      { root: true },
    )
  },
  async addRep({ dispatch }, { id, rep }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/agents/${id}/representatives`,
        data: rep,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
