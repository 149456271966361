<template>
  <div>
    <v-list-tile
      v-if="!navItem.group"
      :class="currentPath === item.to ? 'grey lighten-2' : ''"
      @click="$router.push(item.to)"
    >
      <v-list-tile-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-tile-action>
      <v-list-tile-title>{{ item.title }}</v-list-tile-title>
    </v-list-tile>

    <v-list-group
      v-else
      :prepend-icon="item.icon"
    >
      <v-list-tile slot="activator">
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile>
      <nav-item 
        v-for="(subItem, index) in item.subs"
        :key="subItem.title+index" 
        :nav-item="subItem" 
      />
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: 'NavItem',
  props: {
    navItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    item() {
      return this.navItem
    },
    currentPath() {
      return this.$route ? this.$router.currentRoute.path : ''
    },
  }
}
</script>