/* eslint-disable no-param-reassign */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { schoolName, baseURL } from '@/env.config.json'
import app from './modules/app'
import logins from './modules/logins'
import members from './modules/members'
import prospectiveStudents from './modules/prospective-students'
import prospectiveStudentFees from './modules/prospective-student-fees'
import applications from './modules/applications'
import students from './modules/students'
import fees from './modules/fees'
import studentTypes from './modules/student-types'
import studentFees from './modules/student-fees'
import transactions from './modules/transactions'
import courseschedules from './modules/course-schedules'
import studentcourses from './modules/student-courses'
import terms from './modules/terms'
import courses from './modules/courses'
import agents from './modules/agents'
import representatives from './modules/representatives'
import regions from './modules/regions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    schoolName,
    user: null,
    token: null,
    serverTime: null,
    status: null,
    timeNow: null,
    isValid: null,
    router: null,
    stateMessage: {
      state: 'normal',
      message: '',
    },
    http: axios.create({
      baseURL,
    }),
  },
  getters: {
    schoolName: state => state.schoolName,
    token: state => state.token,
    user: state => state.user,
    serverTime: state => state.serverTime,
    status: state => state.status,
    stateMessage: state => state.stateMessage,
    isValid: state => state.isValid,
    router: state => state.router
  },
  mutations: {
    SET_USER: (state, user) => { state.user = user },
    SET_TOKEN: (state, token) => { state.token = token },
    SET_SERVERTIME: (state, serverTime) => { state.serverTime = serverTime },
    UPDATE_TIMENOW: (state) => { state.timeNow = Math.floor(Date.now() / 1000) },
    SET_LOADING: (state) => { state.status = 'loading' },
    UNSET_STATUS: (state) => { state.status = null },
    SET_STATEMESSAGE: (state, stateMessage) => { state.stateMessage = stateMessage },
    SET_IS_VALID: (state, valid) => { state.isValid = valid },
    SET_ROUTER: (state, router) => { state.router = router },
    SET_BEARER: (state, bearer) => { state.http.defaults.headers.common.Authorization = bearer },
    UNSET_AUTH: (state) => { delete state.http.defaults.headers.common.Authorization }
  },
  actions: {
    // update time every 1 min
    trackTime({ commit }) {
      setInterval(() => {
        commit('UPDATE_TIMENOW')
      }, 1000 * 60)
    },
    setIsValid({ commit }, v) {
      commit('SET_IS_VALID', v)
    },
    setRouter({ commit }, router) {
      commit('SET_ROUTER', router)
    },
    async login({ dispatch }, { username, password }) {
      const token = await dispatch(
        'http',
        {
          method: 'POST',
          url: '/login',
          data: { username, password },
        },
      )
      dispatch('consumeToken', token)
    },
    logout({ dispatch, state }) {
      sessionStorage.clear()
      dispatch('resetState')
      state.router.push('/')
    },
    async renewToken({ dispatch }) {
      const token = await dispatch(
        'http',
        {
          method: 'GET',
          url: '/auth/renew',
        },
      )
      dispatch('consumeToken', token)
    },
    consumeToken({ commit }, token) {
      // store token to session
      sessionStorage.setItem('token', token)
      // decode user
      let [, user] = token.split('.')
      user = user && JSON.parse(atob(user))
      commit('SET_TOKEN', token)
      commit('SET_USER', user)
      commit('SET_SERVERTIME', user.serverTime)
      commit('SET_BEARER', `Bearer ${token}`)
      commit('SET_IS_VALID', true)
      setInterval(() => {
        commit('UPDATE_TIMENOW')
      }, 1000 * 60)
    },
    resetState({ commit }) {
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
      commit('UNSET_AUTH')
      commit('SET_IS_VALID', false)
    },
    setLoading({ commit }){
      commit('SET_LOADING')
    },
    unsetLoading({ commit }){
      commit('UNSET_STATUS')
    },
    async http({ commit, state, dispatch }, config) {
      try {
        commit('SET_LOADING')
        let res = await state.http(config)
        const { data } = res
        return data
      } catch (err) {
        dispatch('handleError', err)
        throw new Error(err)
      } finally {
        commit('UNSET_STATUS')
      }
    },
    handleError({ commit, dispatch }, e) {
      console.log({...e})
      // if response from server presents, show server message
      if(e.response) {
        commit('SET_STATEMESSAGE', { state: 'error', message: e.response.data })
        if(e.response.status === 401 || e.response.status === 403){
          dispatch('logout')
        }
        return
      }

      // else show client-side produced error message
      commit('SET_STATEMESSAGE', { state: 'error', message: e.toString() })
    },
    showMessage({ commit }, { state, message }) {
      commit('SET_STATEMESSAGE', { state, message })
    },
  },
  modules: {
    app,
    logins,
    members,
    prospectiveStudents,
    prospectiveStudentFees,
    applications,
    students,
    fees,
    studentTypes,
    studentFees,
    transactions,
    courseschedules,
    studentcourses,
    terms,
    courses,
    agents,
    representatives,
    regions,
  },
})
