// actions
const actions = {
  getAdmissionYearMonth({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/adm-year-month',
      },
      { root: true },
    )
  },
  getSchoolPrograms({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/school-programs',
      },
      { root: true },
    )
  }
}

export default {
  namespaced: true,
  actions,
}