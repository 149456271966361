// initial state
const state = {
  all: null,
}

// getters
const getters = {
  all: state => state.all,
  getByTypeID: state => typeID => state.all.filter(item => item.typeID === typeID).map(({ feeID }) => feeID),
}

// actions
const actions = {
  init({ commit, dispatch }) {
    dispatch('all')
      .then((data) => {
        commit('SET_STUDENT_FEES', data)
      })
  },
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/student-fees/all',
      },
      { root: true },
    )
  },
  /* getAllUnclearedFees() {
    return new Promise((resolve, reject) => {
      rootState.http.get('/student-fees/unclear/all')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => { reject(err) })
    })
  }, */
  async create({ dispatch, commit }, studentFee) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: '/student-fees/create',
        params: { studentFee },
      },
      { root: true },
    )
    commit('ADD_STUDENT_FEE', studentFee)
    return data
  },
  async delete({ dispatch, commit }, studentFee) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: '/student-fees/delete',
        params: { studentFee },
      },
      { root: true },
    )
    commit('REMOVE_STUDENT_FEE', studentFee)
    return data
  },
}

// mutations
const mutations = {
  SET_STUDENT_FEES: (state, studentFees) => { state.all = studentFees },
  ADD_STUDENT_FEE: (state, studentFee) => { state.all.push(studentFee) },
  REMOVE_STUDENT_FEE: (state, studentFee) => {
    const index = state.all
      .findIndex(item => item.typeID === studentFee.typeID && item.feeID === studentFee.feeID)
    state.all.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
