// initial state
const state = {
  all: [],
}

// getters
const getters = {
  all: state => state.all,
}

// actions
const actions = {
  async create({ dispatch }, { schedule, timings }) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: '/courseschedule/create',
        params: { schedule, timings },
      },
      { root: true },
    )
    return data
  },
  allOngoingSchedules({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/courseschedule/ongoing/all',
      },
      { root: true },
    )
  },
  getScheduleById({ dispatch }, csID) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/courseschedule/${csID}`,
      },
      { root: true },
    )
  },
  getByTermID({ dispatch }, termID) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/courseschedule/term/${termID}`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
