<template>
  <v-dialog
    v-model="dialog"
    width="400px"
  >
    <template v-slot:activator="{ on }">
      <a v-on="on">Forgot Password</a>
    </template>
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Password Assistance
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <p>Enter the email address associated with your account.</p>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="email"
                :rules="[
                  rules.required, 
                  rules.email
                ]"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          flat
          color="primary"
          @click="cont"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validate from '@/custom/validate'

export default {
  data() {
    return {
      dialog: false,
      email: null,
      rules: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.rules = validate.rules
    },
    cont() {
      if(!this.$refs['form'].validate()) return
      this.$store.dispatch('logins/passwordRequest', this.email)
        .then(() => {
          this.dialog = false
          this.$store.dispatch('showMessage', { state: 'success', message: 'Password reset link sent. check your email' })
        })
    },
  }
}
</script>

<style>

</style>
