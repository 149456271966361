<template>
  <v-app>
    <v-dialog
      v-model="login"
      max-width="500px"
    >
      <login @loginSuccess="login = false" />
    </v-dialog>
    <navigator :nav-config="navConfig" />
    <v-toolbar
      app
      :clipped-left="navConfig.clipped"
    >
      <v-toolbar-side-icon @click.stop="navConfig.drawer = !navConfig.drawer" />
      <!-- <v-btn
        icon
        @click.stop="navConfig.miniVariant = !navConfig.miniVariant"
      >
        <v-icon v-html="navConfig.miniVariant ? 'chevron_right' : 'chevron_left'" />
      </v-btn> -->
      <v-btn
        icon
        @click.stop="navConfig.clipped = !navConfig.clipped"
      >
        <v-icon>web</v-icon>
      </v-btn>
      <v-toolbar-title v-text="schoolName" />
      <v-spacer />
      <!-- <div pr-5>
        <v-badge
        v-model="show"
        color="red"
        >
          <span slot="badge" small>6</span>
          <v-icon color="grey">mail</v-icon>
        </v-badge>
      </div> -->
      <v-toolbar-items>
        <v-btn
          v-if="token"
          flat
          @click="logout"
        >
          Log out
        </v-btn>
        <v-btn
          v-else
          flat
          @click="login = true"
        >
          Log in
        </v-btn>
        <v-btn
          v-if="!token"
          flat
          @click="route('/signup')"
        >
          Sign Up
        </v-btn>
        <v-icon>fas fa-lock</v-icon>
      </v-toolbar-items>
    </v-toolbar>
    <v-content>
      <router-view />
    </v-content>
    <v-footer
      :fixed="fixed"
      app
    >
      <span>&copy; 2019</span>
    </v-footer>
    <loading :loading="status === 'loading'" />
    <session-continue-dialog />
    <v-snackbar
      v-model="snackbar"
      :color="stateMessage.state"
      :timeout="6000"
      :top="true"
    >
      {{ stateMessage.message }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Login from '@/components/state/Login.vue'
import Navigator from '@/components/state/Navigator.vue'
import Loading from '@/components/state/Loading.vue'
import SessionContinueDialog from '@/components/state/SessionContinueDialog.vue'

export default {
  name: 'App',
  components: {
    login: Login,
    navigator: Navigator,
    loading: Loading,
    'session-continue-dialog': SessionContinueDialog,
  },
  data() {
    return {
      login: false,
      fixed: false,
      navConfig: {
        clipped: false,
        drawer: true,
        miniVariant: false,
      },
      snackbar: false,
    }
  },
  computed: {
    ...mapState([
      'schoolName',
      'user',
      'token',
      'timeNow',
      'status',
      'stateMessage',
    ]),
  },
  watch: {
    timeNow(now) {
      if (!this.user) return
      const { exp } = this.user
      if (exp - now < 0) {
        this.$store.commit('SET_STATEMESSAGE', { state: 'warning', message: 'Session has Expired' })
        this.logout()
      }
    },
    stateMessage(value) {
      if (value.status !== 'normal') {
        this.snackbar = true
      }
    },
  },
  created() {
    this.appInit()
  },
  methods: {
    appInit() {
      console.log('appInit')
      this.$store.dispatch('setRouter', this.$router)
      const token = sessionStorage.getItem('token')
      if (!token || token === 'null') {
        return
      }
      this.$store.dispatch('consumeToken', token)
      console.log(this.user)
    },
    route(to) {
      this.$router.push(to)
    },
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style>
.required label::after {
    content: " *";
    color: red;
}
</style>
