// initial state
const state = {
  all: [],
}

// getters
const getters = {
  all: state => state.all,
}

// actions
const actions = {
  init({ commit, dispatch }) {
    dispatch('all')
      .then((data) => {
        commit('SET_STUDENT_TYPES', data)
      })
  },
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/student-type/all',
      },
      { root: true },
    )
  },
  async create({ dispatch, commit }, type) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: '/student-type/create',
        params: { type },
      },
      { root: true },
    )
    commit('ADD_STUDENT_TYPE', type)
    return data
  },
}

// mutations
const mutations = {
  SET_STUDENT_TYPES: (state, studentTypes) => { state.all = studentTypes },
  ADD_STUDENT_TYPE: (state, studentType) => { state.all.push(studentType) },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
