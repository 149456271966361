// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/representatives',
        params: filter,
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/representatives/${id}`,
      },
      { root: true },
    )
  },
  async create({ dispatch }, rep) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/representatives',
        data: rep,
      },
      { root: true },
    )
  },
  async update({ dispatch }, rep) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/representatives/${rep.id}`,
        data: rep,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, rep) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/representatives/${rep.id}`,
      },
      { root: true },
    )
  },
  async allAppsById({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/representatives/${id}/applications`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
