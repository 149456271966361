// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/prospective-students',
      },
      { root: true },
    )
  },
  async create({ dispatch }, prospectiveStudent) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/prospective-students',
        data: prospectiveStudent,
      },
      { root: true },
    )
  },
  async createOn({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/prospective-students/${id}`,
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/prospective-students/${id}`,
      },
      { root: true },
    )
  },
  async update({ dispatch }, prospectiveStudent) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/prospective-students/${prospectiveStudent.id}`,
        data: prospectiveStudent,
      },
      { root: true },
    )
  },
  async addFee({ dispatch }, { id, fee }) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/prospective-students/${id}/fees`,
        data: fee,
      },
      { root: true },
    )
  },
  async allFees({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/prospective-students/${id}/fees`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
