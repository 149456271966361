import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: () => import('./views/Profile.vue'),
    },
    {
      path: '/agent-login',
      name: 'agent-login',
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/AgentLogin.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/SignUp.vue'),
    },
    {
      path: '/agents/:id/representatives',
      name: 'agent-representatives',
      component: () => import('./views/Representatives.vue'),
    },
    {
      path: '/reps/:id/applications',
      name: 'rep-view-apps',
      component: () => import('./views/RepViewApps.vue'),
    },
    {
      path: '/prospective-student',
      name: 'reg-view-prospective-student',
      component: () => import('./views/RegViewProspectiveStudent.vue'),
    },
    {
      path: '/users/:id/applications',
      name: 'user-view-apps',
      component: () => import('./views/UserViewApps.vue'),
    },
    {
      path: '/applications/:id',
      name: 'user-edit-app',
      component: () => import('./views/UserEditApp.vue'),
    },
    {
      path: '/student',
      name: 'student',
      component: () => import('./views/Student.vue'),
    },
    {
      path: '/agent',
      name: 'agent',
      component: () => import('./views/Agent.vue'),
    },
    {
      path: '/prospective-students/:id/fees',
      name: 'reg-view-prospective-student-fees',
      component: () => import('./views/RegViewProspectiveStudentFees.vue'),
    },
    {
      path: '/student-account/:studID',
      name: 'student-account',
      component: () => import('./views/StudentAccount.vue'),
    },
    {
      path: '/student-account/:studID/fee/:feeID',
      name: 'student-account-preselect',
      component: () => import('./views/StudentAccount.vue'),
    },
    {
      path: '/courseschedules',
      name: 'course-schedules',
      component: () => import('./views/CourseSchedules.vue'),
    },
    {
      path: '/courseschedule/:csID',
      name: 'course-schedule',
      component: () => import('./views/CourseSchedule.vue'),
    },
    {
      path: '/admin/fees',
      name: 'fees',
      component: () => import('./views/Fees.vue'),
    },
    {
      path: '/admin/student/types',
      name: 'student-types',
      component: () => import('./views/StudentType.vue'),
    },
    {
      path: '/report/student-fees',
      name: 'student-fees-report',
      component: () => import('./views/StudentFeeReport.vue'),
    },
    {
      path: '/school-term',
      name: 'school-term',
      component: () => import('./views/SchoolTerm.vue'),
    },
    {
      path: '/reg-view-apps',
      name: 'reg-view-apps',
      component: () => import('./views/RegViewApps.vue'),
    },
    {
      path: '/password-reset/:id',
      name: 'password-reset',
      component: () => import('./views/PasswordReset.vue'),
    }
  ],
})
