// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/members',
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/members/${id}`,
      },
      { root: true },
    )
  },
  async create({ dispatch }, member) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/members',
        data: member,
      },
      { root: true },
    )
  },
  async findAppsById({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/members/${id}/applications`,
      },
      { root: true },
    )
  },
  async createApplication({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: `/members/${id}/applications`,
      },
      { root: true },
    )
  },
  async update({ dispatch }, member) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/members/${member.id}`,
        data: member,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, member) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/members/${member.id}`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}