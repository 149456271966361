const rules = {
  required: v => !!v || 'This field is required',
  email: v => !v || /^$|^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(v) || 'Not a valid e-mail address',
  postalCode: v => !v || /^.\d. ?\d.\d$/.test(v) || 'Not a valid postal code format',
  date: v => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'Not a valid date format',
  digits: v => !v || /^\d*$/.test(v) || 'Digits only',
  length: (i = 0, n = 0) => v => !v || new RegExp(`^.{${i},${n}}$`).test(v) || `Length between ${i} ~ ${n}`,
}

export default { rules }
