<template>
  <v-navigation-drawer
    v-model="navConfig.drawer"
    persistent
    :mini-variant="navConfig.miniVariant"
    :clipped="navConfig.clipped"
    enable-resize-watcher
    fixed
    app
  >
    <v-list>
      <div
        v-for="(item, i) in navItems"
        :key="i"
      >
        <nav-item :nav-item="item" />
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import nav from './nav.config.json'
import NavItem from './NavItem.vue'

export default {
  name: 'Navigator',
  components: {
    'nav-item': NavItem
  },
  props: {
    navConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      nav
    }
  },
  computed: {
    navItems() {
      const { userId, privilege: role } = this.user || {}

      return this.constructRoutes(nav, { userId, role })
    },
    ...mapState([
      'user',
      'token',
    ]),
  },
  methods: {
    constructRoutes(navItems, { userId, role }) {
      return navItems
        .filter(item => item.enable)
        .filter(item => !item.roles || item.roles.includes(role))
        .map(item => {
          if(item.to)
            item.to = item.to.replace(':userId', userId)
          if(!item.group) return item

          item.subs = this.constructRoutes(item.subs, { userId, role })
          return item
        })
    }
  },
}
</script>
