// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/terms',
      },
      { root: true },
    )
  },
  async create({ dispatch }, term) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/terms',
        data: term,
      },
      { root: true },
    )
  },
  async update({ dispatch }, term) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/terms/${term.id}`,
        data: term,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, term) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/terms/${term.id}`,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
