// initial state
const state = {
  all: [],
}

// getters
const getters = {
  all: state => state.all,
}

// actions
const actions = {
  all({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/students',
        params: filter,
      },
      { root: true },
    )
  },
  getByStudentID({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/student/${id}`,
      },
      { root: true },
    )
  },
  getStudentsByScheduleID({ dispatch }, csID) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/student/get/csID/${csID}`,
      },
      { root: true },
    )
  },
  filter({ dispatch }, filter) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/student/filter',
        params: { filter },
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
  SET_STUDENTS: (state, students) => { state.all = students },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
