<template>
  <v-card class="elevation-12">
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>Sign In</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <v-form ref="form">
            <v-text-field
              v-model="user.username"
              prepend-icon="person"
              name="login"
              label="E-mail / Username"
              type="text"
              :rules="[v => !!v || 'Username is required']"
            />
            <v-text-field
              id="password"
              v-model="user.password"
              prepend-icon="lock"
              name="password"
              label="Password"
              type="password"
              :rules="[v => !!v || 'Password is required']"
            />
          </v-form>
        </v-flex>
        <v-flex
          xs12
          text-xs-right
        >
          <forgot-password-dialog />
        </v-flex>
      </v-layout>
      <!-- <v-alert v-if="fail" :value="true" type="error">
                {{errorMessage}}
            </v-alert> -->
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click.native="login"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ForgotPasswordDialog from '@/components/state/ForgotPasswordDialog.vue'

export default {
  name: 'Login',
  components: {
    'forgot-password-dialog': ForgotPasswordDialog,
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      fail: false,
      errorMessage: 'invalid login, please try again',
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('login', this.user)
          .then(() => {
            this.user.username = ''
            this.user.password = ''
            this.$emit('loginSuccess')
            this.$router.push('/')
          })
          .catch((error) => {
            this.errorMessage = error
            this.fail = true
          })
      }
    },
  },
}
</script>
