// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  getStudentCourseByCsID({ dispatch }, csID) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/studentcourse/byschedule/${csID}`,
      },
      { root: true },
    )
  },
  async create({ dispatch }, { studID, csID }) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: `/studentcourse/create/studID/${studID}/csID/${csID}`,
      },
      { root: true },
    )
    return data
  },
  async delete({ dispatch }, { studID, csID }) {
    const { data } = await dispatch(
      'http',
      {
        method: 'GET',
        url: '/studentcourse/delete',
        params: { studID, csID },
      },
      { root: true },
    )
    return data
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
