// initial state
const state = {
  all: [],
}

// getters
const getters = {
}

// actions
const actions = {
  all({ dispatch }) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/login',
      },
      { root: true },
    )
  },
  one({ dispatch }, id) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: `/login/${id}`,
      },
      { root: true },
    )
  },
  async create({ dispatch }, login) {
    return dispatch(
      'http',
      {
        method: 'POST',
        url: '/login',
        data: login,
      },
      { root: true },
    )
  },
  async update({ dispatch }, login) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/login/${login.id}`,
        data: login,
      },
      { root: true },
    )
  },
  async delete({ dispatch }, login) {
    return dispatch(
      'http',
      {
        method: 'DELETE',
        url: `/login/${login.id}`,
      },
      { root: true },
    )
  },
  async passwordRequest({ dispatch }, email) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/login/password/request',
        params: { email },
      },
      { root: true },
    )
  },
  async passwordSetup({ dispatch }, email) {
    return dispatch(
      'http',
      {
        method: 'GET',
        url: '/login/pass-setup',
        params: { email },
      },
      { root: true },
    )
  },
  async setPassword({ dispatch }, { id, password }) {
    return dispatch(
      'http',
      {
        method: 'PUT',
        url: `/login/${id}/password`,
        data: password,
      },
      { root: true },
    )
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
